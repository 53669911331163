import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { getInitialEffectForEffectType } from "@sutro/studio2-quarantine/definitions/definition-updaters/get-initial-effect-for-effect-type";
import { produce } from "immer";
import { DataType, getUlid, SerializedAction } from "sutro-common";

import { isEffectInstructionIncomplete } from "./is-effect-instruction-incomplete.js";
export const getActionsWithIncompleteAnnotated = ({
  actions,
  dataTypes,
  changeTracker,
}: {
  actions: SerializedAction[];
  dataTypes: DataType[];
  changeTracker: ChangeTracker;
}) =>
  produce(actions, (draftActions) => {
    for (const draftAction of draftActions) {
      delete draftAction.isIncomplete;

      const effectsWithNoId = draftAction.effectInstructions.filter(
        (effectInstruction) => effectInstruction.effectId === undefined
      );

      // Providing IDs for effects who don't already have one. Such cases mostly happen with old definitions / using `setDefinition`
      effectsWithNoId.forEach((effectInstruction) => {
        effectInstruction.effectId = getUlid();
      });

      const incompleteEffectInstructions =
        draftAction.effectInstructions.filter(
          (effectInstruction) =>
            isEffectInstructionIncomplete({ effectInstruction, dataTypes })
              .isIncomplete
        );

      if (incompleteEffectInstructions.length > 0) {
        draftAction.isIncomplete = true;
        changeTracker.trackChange(
          `Removed action ${draftAction.name} because it was incomplete`,
          JSON.parse(
            JSON.stringify(
              draftAction.effectInstructions.find((effectInstruction) =>
                isEffectInstructionIncomplete({ effectInstruction, dataTypes })
              )
            )
          )
        );
      }

      // Wipe out all field instructions of an action whose effect contains instructions referring to dead edge references
      const effectsWithDeadReferences = incompleteEffectInstructions.filter(
        (effectInstruction) => {
          const validation = isEffectInstructionIncomplete({
            effectInstruction,
            dataTypes,
          });
          if (
            validation.isIncomplete &&
            validation.error === "DEAD_DT_EDGE_REFERENCE"
          ) {
            return true;
          }
          return false;
        }
      );

      // Setting the effect to its initial state
      /** @todo:  Ideally we should avoid resetting the whole effect, and only reset the invalid fieldInstructions etc. */
      effectsWithDeadReferences.forEach((effectInstruction) => {
        const initialEffect = getInitialEffectForEffectType(
          effectInstruction.effectType,
          dataTypes
        );
        Object.keys(effectInstruction).forEach((key) => {
          //@ts-expect-error TS7053 - TS doesn't like this kind of copying
          effectInstruction[key] = initialEffect[key];
        });
      });
    }
  });
