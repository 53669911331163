import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { Draft } from "immer";
import pluralize from "pluralize";
import {
  ContainerDataType,
  DataType,
  getUserDt,
  isCreatableContainer,
  isDataTypeEdgeBelongsToEdge,
  isDataTypeEdgeHasEdge,
  isDtUser,
  newEdgeId,
} from "sutro-common";
import type {
  DataTypeEdge,
  IncompleteDataTypeEdge,
} from "sutro-common/edges/data-type-edge";
import {
  EDGE_QUANTITY_TYPES,
  WHO_CAN_SEE_TYPES,
} from "sutro-common/edges/data-type-edge";
import { DATA_TYPE_EDGE_DIRECTION } from "sutro-common/edges/dt-edge-id-and-direction";
import { getReverseEdge } from "sutro-common/edges/get-reverse-edge";
import { isCreatorEdge } from "sutro-common/edges/is-creator-edge";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";

const createCreatorEdgeToDt = ({
  dt,
  userDt,
  whoCanSeeAnnotation,
}: {
  dt: DataType;
  userDt: ContainerDataType;
  whoCanSeeAnnotation?: DataTypeEdge["whoCanSee"];
}) => {
  /*
   * This is some nasty type abuse, but it's a result of our current
   * approach around "incomplete" edges and models.
   */
  const newEdgeFromUser: IncompleteDataTypeEdge = {
    edgeId: newEdgeId(),
    direction: DATA_TYPE_EDGE_DIRECTION.has,
    quantity: EDGE_QUANTITY_TYPES.SOME,
    relatedDtId: dt.id,
    fieldName: pluralize(dt.name),
    isIncomplete: true,
    whoCanSee: whoCanSeeAnnotation,
  };
  userDt.edges.push(newEdgeFromUser as DataTypeEdge);
};

export const updateDtsToEnsureAllContainersHaveAnEdgeFromAUser = ({
  draftDataTypes,
  changeTracker,
}: {
  draftDataTypes: Draft<DataType[]>;
  changeTracker: ChangeTracker;
}) => {
  const userDt = getUserDt(draftDataTypes);
  const dtIdsFromUser = userDt.edges
    .filter(isDataTypeEdgeHasEdge)
    .filter(isDataTypeSomeEdge)
    .map((e) => e.relatedDtId);

  draftDataTypes
    .filter(
      (dt) =>
        isCreatableContainer(dt) &&
        isDtUser(dt) === false &&
        dtIdsFromUser.includes(dt.id) === false
    )
    .forEach((dt) => {
      if (dt.edges.some(isCreatorEdge)) {
        return;
      }

      const belongsToEdgesToUsers = dt.edges.filter(
        (e) => e.relatedDtId === userDt.id && isDataTypeEdgeBelongsToEdge(e)
      );
      if (belongsToEdgesToUsers.length === 0) {
        /** @todo:  this is a bit hacky, we need to rethink how whoCanSee is propagated across different paths, but this will be 90% right */
        const dtHasSingleBelongsToRelationship =
          dt.edges.filter(isDataTypeEdgeBelongsToEdge).length === 1;
        const singleBelongsToRelationshipHasWhoCanSeeOwnerAnnotation =
          dtHasSingleBelongsToRelationship &&
          getReverseEdge({
            edge: dt.edges.find(isDataTypeEdgeBelongsToEdge),
            dataTypes: draftDataTypes,
          })?.whoCanSee?.type === WHO_CAN_SEE_TYPES.OWNER;

        createCreatorEdgeToDt({
          dt,
          userDt,
          whoCanSeeAnnotation:
            singleBelongsToRelationshipHasWhoCanSeeOwnerAnnotation
              ? {
                  type: WHO_CAN_SEE_TYPES.OWNER,
                }
              : undefined,
        });
        changeTracker.trackChange(`Created creator edge to ${dt.name}`);
      }
    });
};
