import { addEdgeIncludingReverseEdge } from "@sutro/studio2-quarantine/definitions/definition-updaters/add-edge-including-reverse-edge";
import {
  getDefaultFieldInstructions,
  getFieldInstructionsByMergingOldAndDefault,
} from "@sutro/studio2-quarantine/lib/field-instruction-helpers";
import { produce } from "immer";
import {
  ContainerDataType,
  Definition,
  EFFECT_TYPES,
  getDt,
} from "sutro-common";
import { getEdgeFromDtsByEdgeId } from "sutro-common/edges/get-edge-from-dts-by-edge-id";
import { VARIABLE_TYPES } from "sutro-common/variable-types";

export const getUpdatedDefinitionBySynchronizingActionsAndDTs = (
  definition: Definition
): Definition =>
  produce(definition, (draftDefinition) => {
    const { dataTypes: draftDataTypes, actions: draftActions } =
      draftDefinition;
    for (const draftAction of draftActions) {
      for (const draftEffect of draftAction.effectInstructions) {
        // If we're creating or editing a DT, ensure the fieldInstructions still match the current version of the DT.
        // Note: this is being crazy often right now for stuff that doesn't really change, so probably find a way to avoid it for efficiency
        // Also note there's probably a more elegant way to do this than to generate the default action again upon every edit, but it's not totally obvious to me right now, so let's do this
        if (
          draftEffect.effectType === EFFECT_TYPES.CREATE ||
          draftEffect.effectType === EFFECT_TYPES.UPDATE
        ) {
          const idOfDTToCreateOrUpdate =
            draftEffect.effectType === EFFECT_TYPES.CREATE
              ? draftEffect.idOfDTToCreate
              : draftEffect.idOfDTToUpdate;

          if (idOfDTToCreateOrUpdate !== "") {
            const draftDtToCreateOrUpdate = getDt(
              draftDataTypes,
              idOfDTToCreateOrUpdate
            ) as ContainerDataType;
            const defaultFieldInstructions = getDefaultFieldInstructions({
              draftDt: draftDtToCreateOrUpdate,
              draftDataTypes,
              effectType: draftEffect.effectType,
            });
            const { newFieldInstructions, addedFieldInstructions } =
              getFieldInstructionsByMergingOldAndDefault({
                oldFieldInstructions: draftEffect.fieldInstructions || [],
                defaultFieldInstructions,
              });
            const actionInput = draftDataTypes.find(
              (dt) => dt.id === draftAction.inputDTId
            ) as ContainerDataType;
            if (actionInput) {
              for (const instruction of addedFieldInstructions) {
                // debugger;
                const { sourceVariable } = instruction;
                if (
                  instruction.forEdgeDirection !== undefined &&
                  instruction.forEdgeId !== undefined &&
                  sourceVariable.variableType === VARIABLE_TYPES.PATH_VALUE &&
                  sourceVariable.directionalEdgeIdPath.length === 0
                ) {
                  const edge = getEdgeFromDtsByEdgeId({
                    dataTypes: draftDataTypes,
                    edgeIdAndDirection: {
                      edgeId: instruction.forEdgeId,
                      direction: instruction.forEdgeDirection,
                    },
                  });
                  if (edge) {
                    const { newEdge } = addEdgeIncludingReverseEdge(
                      draftDefinition,
                      {
                        dtThatEdgeComesFrom: actionInput,
                        edgeMeta: {
                          edgeHasQuantity: edge.quantity,
                          relatedDtId: edge.relatedDtId,
                          fieldName: edge.fieldName,
                        },
                      }
                    );
                    sourceVariable.directionalEdgeIdPath = [
                      { direction: newEdge.direction, edgeId: newEdge.edgeId },
                    ];
                  }
                }
              }
            }
            draftEffect.fieldInstructions = newFieldInstructions;
          }
        }
      }
    }
  });
