// FYI: I have recently moved to modelling that UNIONs do not actually belong to anything. This feels right to me, but other code probably assumes UNIONs belong to things, so we will need to handle that somehow. UNIONs are really just a kind of edge, not a kind of model.
import { ChangeTracker } from "@sutro/studio2-quarantine/definitions/change-tracker";
import { CURRENT_DEFINITION_VERSION, Definition } from "sutro-common";

import { checkDefinitionForRecentChanges } from "~/lib/definitions/definition-validation/check-definition-for-recent-changes";
import { getActionsWithIncompleteAnnotated } from "~/lib/definitions/definition-validation/get-actions-with-incomplete-annotated";
import { getDataTypesWithUnnecessaryActionInputsRemoved } from "~/lib/definitions/definition-validation/get-data-types-with-unnecessary-action-inputs-removed";
import { getDTsWithIncompleteRemoved } from "~/lib/definitions/definition-validation/get-dts-with-incomplete-removed";
import { getUpdatedDefinitionBySynchronizingActionsAndDTs } from "~/lib/definitions/definition-validation/get-updated-definition-by-synchronizing-actions-and-dts";
import { getUpdatedDefinitionFollowingInvariantChecksAndFixes } from "~/lib/definitions/definition-validation/get-updated-definition-following-invariant-checks-and-fixes";
import { updateDefinitionByRunningPlugins } from "~/lib/plugins/update-definition-by-running-plugins";

type ValidationOutcome =
  | {
      updatedDefinition: Definition;
      validatedDefinition: Definition;
      error: undefined;
      changeTracker: ChangeTracker;
    }
  | {
      updatedDefinition: null;
      validatedDefinition: null;
      error: string;
      changeTracker: ChangeTracker;
    };

export const getUpdatedAndValidatedDefinition = ({
  definition,
}: {
  definition: Definition;
}): ValidationOutcome => {
  checkDefinitionForRecentChanges(definition);
  const changeTracker = new ChangeTracker();

  const {
    dataTypesWithChangesAndIncompleteAnnotated,
    success,
    // This validates and updates dataTypes but not actions. This is because actions is updated based on validatedDataTypes which is downstream of here.
    /** @todo:  in the future we'll have steps to check DT invariants, then a final check of cross DT-action invariants later */
  } = getUpdatedDefinitionFollowingInvariantChecksAndFixes({
    definition,
    changeTracker,
  });

  const definitionWithChangesByPlugins = updateDefinitionByRunningPlugins({
    // @ts-expect-error TS2304 it expects appMeta
    definition: {
      dataTypes: dataTypesWithChangesAndIncompleteAnnotated,
      actions: definition.actions,
    },
    changeTracker,
  });

  const dataTypesWithChangesAndIncompleteAnnotatedAndPluginsRun =
    definitionWithChangesByPlugins.dataTypes;

  if (success === false) {
    return {
      error: "Error",
      changeTracker,
      updatedDefinition: null,
      validatedDefinition: null,
    };
  }

  const {
    dataTypes: dataTypesWithActionInputsAdded,
    actions: newActionsWithChanges,
  } = getUpdatedDefinitionBySynchronizingActionsAndDTs({
    ...definitionWithChangesByPlugins,
    dataTypes: dataTypesWithChangesAndIncompleteAnnotatedAndPluginsRun,
    actions: definition.actions,
  });

  /** @todo:  validate actions, and validate actions-DT pair now */
  const dataTypesWithChangesAndIncompleteRemoved = getDTsWithIncompleteRemoved(
    dataTypesWithActionInputsAdded
  );

  const actionsWithIncompleteAnnotated = getActionsWithIncompleteAnnotated({
    actions: newActionsWithChanges,
    dataTypes: dataTypesWithActionInputsAdded,
    changeTracker,
  });

  const actionsWithIncompleteRemoved = actionsWithIncompleteAnnotated.filter(
    (action) => action?.isIncomplete !== true
  );

  const newValidatedDataTypesWithUnusedActionInputsRemoved =
    getDataTypesWithUnnecessaryActionInputsRemoved({
      dataTypes: dataTypesWithChangesAndIncompleteRemoved,
      actions: actionsWithIncompleteRemoved,
      changeTracker,
    });

  return {
    updatedDefinition: {
      ...definition,
      version: CURRENT_DEFINITION_VERSION,
      dataTypes: dataTypesWithActionInputsAdded,
      actions: actionsWithIncompleteAnnotated,
    },
    validatedDefinition: {
      ...definition,
      version: CURRENT_DEFINITION_VERSION,
      dataTypes: newValidatedDataTypesWithUnusedActionInputsRemoved,
      actions: actionsWithIncompleteRemoved,
    },
    error: undefined,
    changeTracker,
  };
};
