import { getEdgesIncludingUnionEdges } from "@sutro/studio2-quarantine/lib/dt-manipulation-helpers";
import { Draft } from "immer";
import {
  DataType,
  DT_TYPES,
  DtId,
  EdgeId,
  isDtActionInput,
  isDtAppRoot,
  isDtContainer,
  isDtUnion,
  not,
  UnionDataType,
} from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import { DATA_TYPE_EDGE_DIRECTION } from "sutro-common/edges/dt-edge-id-and-direction";
import { isDataTypeSomeEdge } from "sutro-common/edges/is-edge-some-edge";
import { IncompleteEdgeStatus } from "sutro-common/plugins/incomplete-status";

import { applyPatchToEdgePluginData } from "~/lib/edge-manipulation-helpers";

/******
 * DT and Edge Predicates
 ******/

const isMissingData = (edge: DataTypeEdge) =>
  edge.fieldName === "" ||
  edge.fieldName === undefined ||
  edge.relatedDtId === undefined;

const isIncompleteEdge = (edge: DataTypeEdge) =>
  isMissingData(edge) ||
  (isDataTypeSomeEdge(edge) &&
    (edge.whoCanHave?.listFieldEdgeId === "" ||
      edge.whoCanAddRemove?.listFieldEdgeId === ""));

const isCompleteEdge = (edge: DataTypeEdge) => edge.isIncomplete !== true;

const isACompleteHasEdge = (edge: DataTypeEdge) =>
  isCompleteEdge(edge) && edge.direction === DATA_TYPE_EDGE_DIRECTION.has;

const hasNoCompleteOutboundEdges = (dt: DataType) =>
  (isDtContainer(dt) && dt.edges.every(not(isACompleteHasEdge))) ||
  (isDtUnion(dt) && dt.containsUnionEdges?.every(not(isCompleteEdge)));

const edgePointsToNonEmptyDt =
  (emptyDTIds: DtId[]) => (unionOfEdge: DataTypeEdge) =>
    emptyDTIds.includes(unionOfEdge.relatedDtId) === false;

const incompleteEdges = (draftDataTypes: Draft<DataType[]>) => {
  draftDataTypes.forEach((dt) =>
    getEdgesIncludingUnionEdges(dt).forEach((edge) => {
      edge.isIncomplete = isIncompleteEdge(edge);
    })
  );
};

/*********
 * DT/Edge Incompleteness rules
 *********/

const dtsWithBlankFieldNames = (draftDataTypes: Draft<DataType[]>) => {
  draftDataTypes.forEach((dt) => {
    if (dt.name.length === 0) {
      dt.isIncomplete = true;
    }
  });
};

const unionsWithNoCompleteEdges = (
  draftDataTypes: Draft<DataType[]>,
  emptyDTIds: DtId[]
) => {
  draftDataTypes.filter(isDtUnion).forEach((dt) => {
    if (
      dt.containsUnionEdges?.some(edgePointsToNonEmptyDt(emptyDTIds)) === false
    ) {
      dt.isIncomplete = true;
      emptyDTIds.push(dt.id);
    }
  });
};

const containersWithDuplicateFieldNames = (
  draftDataTypes: Draft<DataType>[]
) => {
  draftDataTypes
    .filter(isDtContainer)
    .filter((dt) => !isDtAppRoot(dt))
    .forEach((dt) => {
      const completeEdgeFieldNames = new Map<string, EdgeId>();

      dt.edges.forEach((edge) => {
        const conflictingEdgeId = completeEdgeFieldNames.get(edge.fieldName);
        // Marking edges with duplicated `fieldName` as incomplete
        if (isMissingData(edge) === false && conflictingEdgeId !== undefined) {
          edge.isIncomplete = true;
          applyPatchToEdgePluginData(edge, {
            incompleteStatus: {
              reason: IncompleteEdgeStatus.DUPLICATE_FIELDNAME,
              conflictingEdgeId,
            },
          });
        } else {
          // Only add a complete edge's field name to the set to avoid unnecessary insertions to the set when user is still
          // editing the edge
          if (edge.isIncomplete === false) {
            completeEdgeFieldNames.set(edge.fieldName, edge.edgeId);
          }
        }
      });
    });
};

const edgesToAndFromEmptyDt = (
  draftDataTypes: Draft<DataType>[],
  emptyDTIds: DtId[]
) => {
  const inProgressEdgeIDs = new Set();
  const allEdges = draftDataTypes.flatMap((dt) =>
    getEdgesIncludingUnionEdges(dt)
  );

  allEdges.forEach((edge) => {
    if (emptyDTIds.includes(edge.relatedDtId)) {
      edge.isIncomplete = true;
    }
    if (edge.isIncomplete) {
      inProgressEdgeIDs.add(edge.edgeId);
    }
  });

  allEdges.forEach((edge) => {
    if (inProgressEdgeIDs.has(edge.edgeId)) {
      edge.isIncomplete = true;
    }
  });
};

export const unusedEnumsAndIdentities = (draftDataTypes: Draft<DataType[]>) => {
  const toBeRemovedDtIds: DtId[] = [];

  draftDataTypes
    .filter(isDtUnion)
    .filter((dt) => dt.type === DT_TYPES.ENUM && dt.edges.length === 0)
    .forEach((unusedEnumDt: UnionDataType) => {
      toBeRemovedDtIds.push(unusedEnumDt.id);
      unusedEnumDt.containsUnionEdges?.forEach((edge) => {
        toBeRemovedDtIds.push(edge.relatedDtId);
      });
    });

  draftDataTypes
    .filter((dt) => toBeRemovedDtIds.includes(dt.id))
    .forEach((dt) => {
      dt.isIncomplete = true;
    });
};

const containersAndUnionsWithNoOutboundEdges = (
  draftDataTypes: Draft<DataType>[],
  emptyDTIds: DtId[]
) => {
  draftDataTypes.filter(hasNoCompleteOutboundEdges).forEach((emptyDT) => {
    /** @todo:  should be able to deprecate this check since these DTs should now be DT_TYPES.TRANSIENT_INPUT_CONTAINER */
    if (
      isDtUnion(emptyDT) ||
      (isDtContainer(emptyDT) && isDtActionInput(emptyDT) === false)
    ) {
      emptyDT.isIncomplete = true;
      emptyDTIds.push(emptyDT.id);
    }
  });
};

const getMarker =
  (draftDataTypes: Draft<DataType>[], emptyDtIds: DtId[]) =>
  (category: (draftDataTypes: Draft<DataType>[], emptyDtIds: DtId[]) => void) =>
    category(draftDataTypes, emptyDtIds);

/** @todo:  we do not handle this case where a DT becomes empty only after removing its contained isIncomplete edges. */
// See https://github.com/SutroOrg/Sutro/pull/354/files#r884188012
export const markDTsOrEdgesAsIncomplete = (
  draftDataTypes: Draft<DataType[]>
) => {
  for (const dt of draftDataTypes) {
    // Remove what we computed last time around
    delete dt.isIncomplete;
  }

  const markAsIncomplete = getMarker(draftDataTypes, []);

  markAsIncomplete(dtsWithBlankFieldNames);

  markAsIncomplete(unusedEnumsAndIdentities);

  markAsIncomplete(incompleteEdges);

  markAsIncomplete(unionsWithNoCompleteEdges);

  markAsIncomplete(containersAndUnionsWithNoOutboundEdges);

  markAsIncomplete(edgesToAndFromEmptyDt);

  markAsIncomplete(containersWithDuplicateFieldNames);
};
