import { DataType, getDt } from "sutro-common";
import type { DataTypeEdge } from "sutro-common/edges/data-type-edge";
import { DataTypeEdgeIdAndDirection } from "sutro-common/edges/dt-edge-id-and-direction";

export const getDTByFollowingEdges = ({
  fromDt,
  edges,
  dts,
}: {
  fromDt: DataType;
  edges: DataTypeEdgeIdAndDirection[];
  dts: DataType[];
}): DataType | undefined => {
  let currDt: DataType | undefined = fromDt;
  for (const { edgeId, direction } of edges) {
    const edgeFromCurrDT: DataTypeEdge | undefined = currDt?.edges.find(
      (e) => e.edgeId === edgeId && e.direction === direction
    );
    if (edgeFromCurrDT === undefined) {
      return undefined;
    } else {
      currDt = getDt(dts, edgeFromCurrDT.relatedDtId);
    }
  }
  return currDt;
};
